module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon-trt.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"fe3dfc63f08807133c7c1f040dbe9871"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"usatrt","defaultLang":"en-us","langs":["en-us","ru","ar-ae","ka-ka","de-de","pl","uk-uk"],"path":"/preview","previews":true,"pages":[{"type":"BlogPage","match":"/:lang?/blog/:uid","path":"/","component":"/opt/jenkins/workspace/usatrt/usatrt-prod/trt-website-deploy-prod/src/templates/blog-post.js","langs":["en-us","ru","ar-ae","ka-ka","de-de","pl","uk-uk"]},{"type":"BlogCategory","match":"/:lang?/blog/:uid","path":"/","component":"/opt/jenkins/workspace/usatrt/usatrt-prod/trt-website-deploy-prod/src/templates/blog-section.js","langs":["en-us","ru","ar-ae","ka-ka","de-de","pl","uk-uk"]},{"type":"BlogMainPage","match":"/:lang?/blog","path":"/","component":"/opt/jenkins/workspace/usatrt/usatrt-prod/trt-website-deploy-prod/src/templates/blog.js","langs":["en-us","ru","ar-ae","ka-ka","de-de","pl","uk-uk"]},{"type":"LandingPage","match":"/:lang?/landing/:uid","path":"/","component":"/opt/jenkins/workspace/usatrt/usatrt-prod/trt-website-deploy-prod/src/templates/landing-page.js","langs":["en-us","ru","ar-ae","ka-ka","de-de","pl","uk-uk"]},{"type":"ContentPage","match":"/:lang?/:uid","path":"/","component":"/opt/jenkins/workspace/usatrt/usatrt-prod/trt-website-deploy-prod/src/templates/content-page.js","langs":["en-us","ru","ar-ae","ka-ka","de-de","pl","uk-uk"]},{"type":"ContentPage","match":"/:lang?/","path":"/","component":"/opt/jenkins/workspace/usatrt/usatrt-prod/trt-website-deploy-prod/src/templates/index.js","langs":["en-us","ru","ar-ae","ka-ka","de-de","pl","uk-uk"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-64927132-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"optimizeId":"YOUR_GOOGLE_OPTIMIZE_TRACKING_ID","experimentId":"YOUR_GOOGLE_EXPERIMENT_ID","variationId":"YOUR_GOOGLE_OPTIMIZE_VARIATION_ID","sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"usatrt.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
